class SubmitOnChange
{
    constructor(selector){
        this.elements = document.querySelectorAll(selector);

        this.addEventListener();
    }

    addEventListener()
    {
        this.elements.forEach(ele => {
            ele.addEventListener('change' , () => {
                let form = ele.closest('form');
                form.submit();
            });
        });
    }
}

export default SubmitOnChange;