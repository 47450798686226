import Swiper, { Pagination } from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";
import { isIE11 } from "./utils";

class SwiperSlider {
    constructor(selector) {
        if (!document.querySelector(selector)) {
            return;
        }

        if (isIE11()) {
            return;
        }

        this.breakpoint = window.matchMedia("(min-width:1400px)");
        this.slider = undefined;
        this.selector = selector;

        this.sliderSettings = {
            loop: true,
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                // when window width is >= 480px
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            },
        };

        Swiper.use([Pagination]);

        this.addListener();
    }

    addListener() {
        this.breakpoint.addListener(this.maybeDestroySlider.bind(this));
        this.maybeDestroySlider();
    }

    maybeDestroySlider() {
        if (this.breakpoint.matches === true) {
            if (this.slider !== undefined) {
                if (Array.isArray(this.slider)) {
                    this.slider.forEach((slider) => slider.destroy(true, true));
                } else {
                    this.slider.destroy(true, true);
                }
            }
        } else {
            this.initialiseSliders();
        }
    }

    initialiseSliders() {
        this.slider = new Swiper(this.selector, this.sliderSettings);
    }
}

export default SwiperSlider;
