export default class MobileMenu {
    constructor() {
        this.openButton = document.querySelector(".toggle");
        this.closeButton = document.querySelector(".close");

        this.overlay = document.querySelector('.gradient-overlay');

        this.target = document.querySelector(
            "#" + this.openButton.getAttribute("aria-controls")
        );

        this.openButton.onclick = e => this.open();
        this.closeButton.onclick = e => this.close();
    }
    isOpen() {
        return this.target.classList.contains('-open');
    }
    open() {
        this.openButton.setAttribute("aria-expanded", "true");
        this.closeButton.setAttribute("aria-expanded", "true");
        this.target.classList.add("-open");

        this.overlay.classList.add("-show");
    }
    close() {
        this.openButton.setAttribute("aria-expanded", "false");
        this.closeButton.setAttribute("aria-expanded", "false");
        this.target.classList.remove("-open");
        this.overlay.classList.remove("-show");

        this.openButton.focus()
    }
}
