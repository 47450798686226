import Swiper, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

class HeroSlider {
    constructor() {
        Swiper.use([Autoplay]);

        new Swiper(".hero-slider", {
            loop: true,
            speed: 3000,
            autoplay: true,
        });
    }
}

export default HeroSlider;
