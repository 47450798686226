class CleanAirChampion
{
    constructor()
    {
        this.links = document.querySelectorAll('.clean-air-champion .read-more');

        this.addEventListeners();
    }

    addEventListeners()
    {
        this.links.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault();

                let block = e.target.closest('.clean-air-champion');
                block.classList.toggle('-js-show-full-profile');
            })
        });
    }
}

export default CleanAirChampion;