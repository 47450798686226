class GravityFormsLabels {

    constructor(){
        this.inputs = [
            {
                inputSelector: '.name_first input[type="text"]',
                parentSelector: '.name_first',
                classToAdd: 'text',
            },
            {
                inputSelector: '.name_last input[type="text"]',
                parentSelector: '.name_last',
                classToAdd: 'text',
            },
            {
                inputSelector: '.gfield textarea',
                parentSelector: '.gfield',
                classToAdd: 'textarea',
            },
            {
                inputSelector: '.gfield_radio',
                parentSelector: '.gfield',
                classToAdd: 'radio',
            },
            {
                inputSelector: '.gfield select',
                parentSelector: '.gfield',
                classToAdd: 'dropdown',
            },
            {
                inputSelector: '.gfield .ginput_container_checkbox',
                parentSelector: '.gfield',
                classToAdd: 'checkbox',
            },
            {
                inputSelector: '.gfield .ginput_container_consent',
                parentSelector: '.gfield',
                classToAdd: 'consent',
            },
            {
                inputSelector: '.gfield input[type="text"]',
                parentSelector: '.gfield',
                classToAdd: 'text',
            },
            {
                inputSelector: '.gfield input[type="password"]',
                parentSelector: '.gfield',
                classToAdd: 'password',
            },
            {
                inputSelector: '.gfield .ginput_complex',
                parentSelector: '.gfield',
                classToAdd: 'complex',
                ignoreFocus: true,
                isComplex: true,
            }
        ];

        $(document).on('gform_post_render', () => this.addClasses());
        $(document).on('gform_post_render', () => this.checkOnLoad());

        this.addEventListeners();

    }

    /**
     * Add relevent event listeners to add/remove active class
     *
     * @return  {void}
     */
    addEventListeners() {
        let $this = this;

        this.inputs.forEach(item => {
            $(document).on('input paste', item['inputSelector'], (e) => {
                if(!item['ignoreFocus']){
                    $this.setActivePositionIfHasValue($(e.currentTarget), item['parentSelector']);
                }
            });
    
            $(document).on('focus', item['inputSelector'], (e) => {
                if(!item['ignoreFocus']){
                    $this.setActivePosition($(e.currentTarget), item['parentSelector']);
                }
            });
    
            $(document).on('blur cut', item['inputSelector'], (e) => {
                if(!item['ignoreFocus']){
                    $this.removeActivePositionIfNoValue($(e.currentTarget), item['parentSelector']);
                }
            });
        });
    }

    /**
     * Is the element part of a complex field?
     *
     * @param   {JQuery Element}  parentElement  Parent HTML element
     *
     * @return  {boolean}  True, if part of a complex field (e.g name) else false
     */
    isPartOfComplexInput(parentElement) {
        return parentElement.find('.ginput_complex').length;
    }

    /**
     * Add classes to elements
     *
     * @return  {void}
     */
    addClasses(){
        console.log("Add classes");
        this.inputs.forEach(item => {
            let foundInputs = document.querySelectorAll(item['inputSelector']);

            for (let i = 0; i < foundInputs.length; ++i) {
                let parent = $(foundInputs[i]).closest(item['parentSelector']);
                console.log(parent);
                if(parent.length && (!this.isPartOfComplexInput(parent) || item['isComplex'])){
                    parent.addClass(item['classToAdd']);
                }
            }
        });
    }

    /**
     * Should we add a class to an element?
     *
     * @param   {Array}  element  Elements
     *
     * @return  {boolean}           True if we should add a class, else false
     */
    shouldAddClass(element)
    {
        if(!element.length){
            return false;
        }

        // Shouldn't add classes to complex fields unless specified
        if(this.isPartOfComplexInput(parent) && !item['isComplex']){
            return false;
        }

        return true;
    }

    /**
     * Add active classes on load, if needed
     *
     * @return  {void}
     */
    checkOnLoad() {
        this.inputs.forEach(item => {
            $(item['inputSelector']).each((idx, element) => {
                if(!item['ignoreFocus']){
                    this.setActivePositionIfHasValue($(element), item['parentSelector']);
                }
            });
        });
    }

    /**
     * Set a field to be active, if it has a value
     *
     * @param   {Element}  obj     Element
     * @param   {Element}  parent  Parent selector of the element
     *
     * @return  {void}
     **/
    setActivePositionIfHasValue(obj, parent) {

        if (obj.val() != '') {
            this.setActivePosition(obj, parent);
        }

    }

    /**
     * Set the element to be active
     *
     * @param   {Element}  obj     Input element
     * @param   {Element}  parent  Parent to add class to
     *
     * @return  {void}
     */
    setActivePosition(obj, parent) {
        let parentElement = obj.closest(parent);

        if(!this.isPartOfComplexInput(parentElement)){
            parentElement.find('label').addClass('-active');
        }
    }

    /**
     * Remove the active class if the input doesn't have a value
     *
     * @param   {Element}  obj     Input element
     * @param   {Element}  parent  Parent to remove class from
     *
     * @return  {void}
     */
    removeActivePositionIfNoValue(obj, parent) {
        if (obj.val() == '') {
            obj.closest(parent).find('.-active').removeClass('-active');
        }
    }

}

export default GravityFormsLabels;