class SearchOverlay {
    constructor()
    {
        this.overlay = document.querySelector("#search-overlay");

        if(this.overlay){
            this.backButton = this.overlay.querySelector('.back-button');
            this.openButton = document.querySelector('.search-open');
            this.closeButton = document.querySelector('.search-close');
            this.searchField = this.overlay.querySelector('.search-field');

            this.addClickHandlers();
        }
    }

    addClickHandlers()
    {
        this.openButton.onclick = e => {
            this.open();
        }

        this.backButton.onclick = e => {
            this.close();
        }

        this.closeButton.onclick = e => {
            this.close();
        }
    }

    open()
    {
        if(!this.isOpen()){
            this.overlay.classList.add('-open');
            this.closeButton.classList.add('-show');
    
            this.searchField.focus();
        }
    }

    isOpen(){
        return this.overlay.classList.contains('-open');
    }

    close()
    {
        if(this.isOpen()){
            this.overlay.classList.remove('-open');
            this.closeButton.classList.remove('-show');
    
            this.openButton.focus();
        }
    }
}

export default SearchOverlay;